import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation } from "@intelligentlilli/api-layer";
import theme from "../../Styles/theme.scss";
import { format } from "date-fns";

const FallsDashboardHeader = ({ error, lastFetched, getError }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web")
        .then((res) => res.ok && setOrganisation(res.body))
        .catch((err) => {
          console.log(err);
          if (err.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [server, user.organisationId, navigate]);

  return (
    <div className={styles.dashboard_header_container}>
      <div className={styles.dashboard_header_container_left}>
        <img
          src="/images/CareHomeLogo.png"
          alt="Location of QR code"
          width={110}
        />
        <div>
          <h1 className={styles.page_title}>Dashboard</h1>
          <div className={styles.user_info} data-private>
            {user?.forenames} {user?.surname} | {organisation?.name || ""}
          </div>
        </div>
      </div>

      {getError ? (
        <div>
          <h3 className={styles.error}>Unable to fetch data</h3>
        </div>
      ) : null}
      {lastFetched ? (
        <div>Last fetched: {format(lastFetched, "dd/MM/yy HH:mm")}</div>
      ) : null}
      {error && (
        <div>
          <h3 className={styles.error}>{error}</h3>
          <div>
            Check your connection and, if problem persists, please contact
            support on:
          </div>
          <a
            href="mailto: support@intelligentlilli.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: theme.secondary3 }}
          >
            support@intelligentlilli.com
          </a>
        </div>
      )}
    </div>
  );
};

export default FallsDashboardHeader;
